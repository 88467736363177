body {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    min-height: 100%;
    /* Ensure that body height stretches with the content */
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
    background-color: #060a4e;
    /* If you want to set a fallback background color */
}

.title {
    font-size: 4em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
    color: #fff;
    padding-top: 1em;
}

h3 {
    padding-top: 0.25em;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
        0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
}

.date {
    color: #fff;
    font-weight: bold;
}

#description {
    margin: 1.5em 0 2em 0;
}

.button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: #fff;
    background-color: #060a4e;
}

.workButton {
    background-color: #06d6a0;
}

.workButton:hover {
    background-color: #0ac593;
}

.schoolButton {
    background-color: #f9c74f;
}

.schoolButton:hover {
    background-color: #f3bc3c;
}

@media only screen and (max-width: 1169px) {
    .vertical-timeline-element-date {
        display: block !important;
        float: none !important;
        color: rgb(44, 44, 44);
        margin-top: 1.5em;
    }
}