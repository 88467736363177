/* Add this to your Footer.css */
.footer .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distributes space between items */
  align-items: center; /* Vertical alignment */
  text-align: center;
}

.footer .footer-social-icons {
  display: flex;
  justify-content: center;
  padding: 0;
}

.footer .footer-social-icons li {
  list-style: none;
  margin: 0 10px; /* Add some horizontal space between icons */
}

@media (max-width: 576px) { /* Adjusting for smaller screens */
  .footer .container {
    flex-direction: column;
  }

  .footer .col-md-6 {
    margin-bottom: 15px; /* Add some margin when stacked on small screens */
  }
}
