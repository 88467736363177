.experience-container {
    padding: 500px;
    text-align: center;
}

.experience-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.timeline {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 10000;
}

.timeline-container {
    position: relative;
    /* Relative to the viewport */
    z-index: 2;
    /* Above the fixed background */
    overflow-y: auto;
    /* Enable scrolling for the timeline */
}

.timeline-item {
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px;
}

.timeline-dot {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #007bff;
}

.timeline-date {
    margin-bottom: 5px;
    font-weight: bold;
}

.timeline-content h3 {
    margin-top: 0;
    font-weight: bold;
}