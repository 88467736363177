.navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #06084e;
    transition: background-color 0.5s ease-in-out, box-shadow 0.3s;
    padding: 20px 0;
    z-index: 3000;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar.colorChange {
    background-color: #FFFFFF !important;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: transform 0.3s ease;
}

.navbar-nav li {
    margin: 0 20px;
}

.navbar-nav a {
    text-decoration: none;
    color: #FFF;
    font-size: 16px;
    padding: 8px 15px;
    transition: color 0.3s, background-color 0.3s, transform 0.3s;
    border-radius: 5px;
    background-color: transparent;
}

.navbar-toggler {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    padding: 10px 20px;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        position: fixed;
        /* Ensure it stays fixed on mobile */
        width: 100%;
        /* Make sure it spans the full width */
        top: 0;
        /* Stay at the top */
        left: 0;
        /* Align left */
    }

    .navbar-nav {
        flex-direction: column;
        width: 100%;
        display: none;
        /* Hide initially */
        position: absolute;
        /* It’s fine for the nav to be absolute within the fixed navbar */
        top: 60px;
        /* Adjust this value based on your navbar's height */
        left: 0;
        background-color: #06084e;
    }

    .navbar-nav.show {
        display: flex;
        /* Show when toggled */
    }

    .navbar-toggler {
        display: block;
        /* Show the toggle button on small screens */
    }

    .navbar-nav a {
        color: #FFF;
        padding: 10px 15px;
        text-align: center;
        width: 100%;
        display: block;
    }

    .navbar.colorChange .navbar-nav {
        background-color: #FFFFFF;
    }

    .navbar.colorChange .navbar-nav a {
        color: #333;
    }
}

.navbar-nav a:hover,
.navbar-nav a:focus {
    color: #1a73e8;
    background-color: #ffffff;
    box-shadow: 0 0 8px 0 rgba(26, 115, 232, 0.75);
    transform: scale(1.05);
}