/* General style for desktop */
.fab-container .MuiFab-root {
    font-size: 0.875rem;
    /* default size */
    padding: 6px 10px;
    /* default padding */
}

/* Media query for devices with a max-width of 600px (common breakpoint for mobile devices) */
@media (max-width: 600px) {
    .fab-container .MuiFab-root {
        font-size: 0.7rem;
        /* smaller font size for mobile */
        padding: 4px 8px;
        /* reduced padding for mobile */
        margin: 2px;
        /* tighter spacing between buttons */
    }
}