/* Projects.css */
.project-container {
    padding: 2rem;
    /* Adds padding around the container */
    max-width: 1200px;
    /* Sets a max-width for the container */
    margin: 0 auto;
    /* Centers the container in the middle of the page */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Aligns the grid items to the center of the 'project-container' */
}

/* Assuming you're using a dark background, as suggested by 'experience-background' */
.project-container h1 {
    color: white;
    margin-bottom: 2rem;
    /* Spacing between your title and the grid */
}

.grid-container {
    width: 100%;
    /* Ensures the grid takes up the full width of the 'project-container' */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    /* This sets the gap between the grid items */
    padding: 20px;
    /* Add padding around the grid */
    justify-content: center;
    /* Center the grid items */
}