/*
GENERAL STYLING
*/
body {
  background-color: #ffffff !important;
  color: #4e4e4e !important;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  /* Prevent horizontal scroll */
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
}

a {
  color: #1e1e1e;
  transition: all 0.5s ease-in-out;
}

a:hover {
  color: #0078ff;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.p-r {
  position: relative;
}

.color-a {
  color: #0078ff;
}

.color-d {
  color: #f5f5f5;
}

.color-text-a {
  color: #4e4e4e;
}

.box-shadow,
.paralax-mf,
.service-box,
.work-box,
.card-blog {
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

.box-shadow-a,
.button:hover {
  box-shadow: 0 0 0 4px #cde1f8;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  overflow: hidden;
}

.bg-image {
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center center;
}

/* 
.overlay-mf {
  background-color: #0078ff;
} */

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: .7;
}

.paralax-mf {
  position: relative;
  padding: 8rem 0;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
  z-index: 1;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/* Sections */

.sect-4 {
  padding: 4rem 0;
}

.sect-pt4 {
  padding-top: 4rem;
}

.sect-mt4 {
  margin-top: 4rem;
}

/* .btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #8064A2 !important;
  border-color: #8064A2
} */

/* Title S */

.title-s {
  font-weight: 600;
  color: #1e1e1e;
  font-size: 1.1rem;
}

/* Title A */

.title-box {
  margin-bottom: 1rem;
}

.title-a {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.title-b {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle-a {
  color: #4e4e4e;
}

.line-mf-main {
  width: 120px;
  height: 5px;
  background-color: #0078ff;
  margin: 0 auto;
}

.line-mf {
  width: 40px;
  height: 5px;
  background-color: #0078ff;
  margin: 0 auto;

}

.swiper-item {
  /* position: relative; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  padding-bottom: 20px;

}

/* Title Left */

.title-box-2 {
  margin-bottom: 3rem;
}

.title-left {
  font-size: 2rem;
  position: relative;
}

.title-left:before {
  content: '';
  position: absolute;
  height: 3px;
  background-color: #0078ff;
  width: 100px;
  bottom: -12px;
}

/* Box */

.box-pl2 {
  padding: 1rem;
  /* Add right padding to match the top and bottom */
  background-color: #060a4e;
  /* Light background for the box */
  border: 1px solid #060a4e;
  /* Subtle border to define the box edges */
  border-radius: 8px;
  /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  margin-bottom: 1rem;
  /* Space below the box */
  transition: all 0.3s ease-in-out;
  /* Smooth transition for hover effects */
  max-width: 1500px;
  margin: 1rem auto;
  width: 80%;
}

.box-pl2:hover {
  transform: translateY(-5px);
  /* Slight raise of the box on hover for an interactive feel */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover for emphasis */
}

.box-shadow-full {
  padding: 3rem 1.25rem;
  position: relative;
  background-color: #fff;
  margin-bottom: 3rem;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* Socials */

.socials {
  padding: 1.5rem 0;
}

.socials ul li {
  display: inline-block;
}

.socials .ico-circle {
  height: 40px;
  width: 40px;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 1.4;
  margin: 0 15px 0 0;
  box-shadow: 0 0 0 3px #0078ff;
  transition: all 500ms ease;
}

.socials .ico-circle:hover {
  background-color: #0078ff;
  color: #fff;
  box-shadow: 0 0 0 3px #cde1f8;
  transition: all 500ms ease;
}

/* Ul resect */

.ul-resect,
.socials ul,
.list-ico,
.blog-wrapper .post-meta ul,
.box-comments .list-comments,
.widget-sidebar .list-sidebar,
.widget-tags ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-ico {
  line-height: 2;
}

.list-ico span {
  color: #0078ff;
  margin-right: 10px;
}

/* Ico Circle */

.ico-circle {
  height: 100px;
  width: 100px;
  font-size: 2rem;
  border-radius: 50%;
  line-height: 1.55;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 0 10px #0078ff;
  display: block;
}

/* Owl Carousel */

.owl-theme .owl-dots {
  text-align: center;
  margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 7px;
  margin: 5px 5px;
  background: #cde1f8;
  border: 0px solid #cde1f8;
  display: block;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #cde1f8;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #1B1B1B;
  width: 25px;
}

/* Scrolltop S */

.scrolltop-mf {
  position: relative;
  display: none;
}

.scrolltop-mf span {
  z-index: 999;
  position: fixed;
  width: 42px;
  height: 42px;
  background-color: #0078ff;
  opacity: .7;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  top: auto;
  left: auto;
  right: 30px;
  bottom: 50px;
  cursor: pointer;
  border-radius: 50%;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #0078ff;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

/* Prelaoder */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #0078ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.intro {
  height: 100vh;
  position: relative;
  color: #fff;
}

.intro .intro-content {
  text-align: center;
  position: absolute;
}

.intro .overlay-intro {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: .9;
}

.intro .intro-title {
  color: #fff;
  font-weight: 600;
  font-size: 3rem;
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

/* .intro .text-slider-items {
  font-weight: bolder;
} */

.intro-single {
  height: 350px;
}

.intro-single .intro-content {
  margin-top: 30px;
}

.intro-single .intro-title {
  text-transform: uppercase;
  font-size: 3rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  color: #0078ff;
}

.intro-single .breadcrumb .breadcrumb-item:before {
  color: #cde1f8;
}

.intro-single .breadcrumb .breadcrumb-item.active {
  color: #cde1f8;
}

.intro-single .breadcrumb a {
  color: #fff;
}

/*
ABOUT
*/

/* .about-mf .box-shadow-full {
  padding-top: 4rem;
  padding-bottom: 4rem;
} */

.about-mf .about-img {
  margin-bottom: 2rem;
}

.about-mf .about-img img {
  margin-left: 10px;
}

.skill-mf span {
  color: #4e4e4e;
}

.skill-mf .progress {
  background-color: #cde1f8;
  margin: .5rem 0 1.2rem 0;
  border-radius: 0;
  height: .7rem;
}

.skill-mf .progress .progress-bar {
  height: .7rem;
  background-color: #0078ff;
}

.bubble-container {
  background: linear-gradient(to bottom, #e0f2ff 0%, #ffffff 100%);
  /* Sky-like gradient */
  border-radius: 25px;
  /* Rounded edges for bubble effect */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  /* Subtle shadow to create a floating effect */
  padding: 50px;
  /* Space inside the container */
  margin: 30px auto;
  /* Center the container with some space from other content */
  max-width: 800px;
  /* Maximum width of the container */
  text-align: center;
  /* Center the text inside the container */
}


.about-mf {
  padding-top: 4rem;
  /* Adjust top padding as per your layout */
  padding-bottom: 4rem;
  /* Adjust bottom padding as per your layout */
}

.container {
  padding-right: 15px;
  /* Adjustments for container paddings */
  padding-left: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-6 {
  position: relative;
  width: 50%;
  /* For a half-half two-column layout */
  padding-right: 15px;
  /* Adjustments for column paddings */
  padding-left: 15px;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.about-img img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  /* If you want a rounded image */
}

/* About Info Text Styling */
.about-info {
  font-family: 'Courier New', Courier, monospace;
  /* Apply Courier New font */
  color: #ffffff;
  /* Sets a light color for the text for readability */
  line-height: 1.6;
  /* This defines the space between lines of text */
  padding: 2rem;
  /* Adds padding inside the container for spacing */
  background: #0C2461;
  /* Background color of the text box */
  border-radius: 8px;
  /* Rounded corners for a modern look */
}

.btn-margin-right {
  margin-right: 20px;
  /* Adjust this value as needed */
}

.contact-background {
  position: relative;
  text-align: center;
  padding: 50px;
}

.contact-icons a {
  display: inline-block;
  /* Ensures the anchor takes the size of its content */
  margin: 0 40px;
  /* Add some horizontal space between icons */
  color: #ffffff;
  /* Set your desired color */
  text-decoration: none;
  /* Removes underline from links */
  z-index: 1000;

  /* spacing between icons */

}

.contact-icons a:hover,
.contact-icons a:focus {
  color: #dddddd;
  /* Set your desired hover color */
  text-decoration: none;
  /* Optional: Removes underline on hover */
}

/* Additional styles for the area and circles */

/* Add styles for .area and .circles as you already have */

.fade-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
  background-color: #007bff;
  /* Replace with your desired background color */
}

.fade-enter-active {
  opacity: 1;
  background-color: transparent;
  /* Transition to transparent or final background color */
  /* transition: opacity 0.1s ease-in-out, background-color 0.1s ease-in-out; */
}

.button-container {
  display: flex;
  justify-content: center;
  /* Centers button horizontally */
  align-items: flex-end;
  /* Aligns button at the bottom */
  margin-top: 2rem;
  /* Pushes the button to the bottom */
}

.about-info h2 {
  font-size: 3rem;
  /* Enlarges the title */
  margin-bottom: 1rem;
  /* Adds a little space below the title */
  /* Add specific color styles for highlight */
  /* background-color: #4a69bd; */
  /* Background highlight color for text */
  color: #fff;
  display: inline;
  /* Makes background color wrap text tightly */
  padding: 0.2rem;
  /* Padding inside the highlight */
}

.about-info p {
  font-size: 1.5rem;
  /* Increases the paragraph font size */
  margin-bottom: 1rem;
  /* Adds space between the paragraphs */
}

/* Highlighted Words Styling */
.highlight {
  color: #3f86f2;
  /* Background highlight color */
  padding: 0.2rem;
  /* Padding inside the highlight */
}

/* Larger box */
.box-pl2 {
  max-width: 100%;
  /* Use the full width of the parent element */
  padding: 4rem;
  /* Increase padding for larger text */
  /* Rest of your styles */
}

/* If you want to style lists, links, or other elements within the about-info, add more rules here */
.about-info a {
  color: #007bff;
  /* Match the link color to the heading color */
  text-decoration: underline;
  /* Underline for links */
}

.about-info ul {
  padding-left: 1.5rem;
  /* Indent for unordered lists */
}

.about-info li {
  margin-bottom: 0.5rem;
  /* Spacing between list items */
}

.contact-title {
  font-size: 2em;
  color: white;

}

@media (max-width: 768px) {
  .about-info {
    font-size: 1rem;
    /* Smaller font size for mobile */
    padding: 1rem;
    /* Less padding */
  }

  .contact-title {
    padding-top: 80px;
    font-size: 2em;
    color: white;

  }

  .contact-icons a {
    display: inline-block;
    /* Ensures the anchor takes the size of its content */
    margin: 0 32px;
    /* Add some horizontal space between icons */

    margin-top: 20px;
    /* Add vertical space between icons */


    color: #ffffff;
    /* Set your desired color */
    text-decoration: none;
    /* Removes underline from links */
    z-index: 1000;

    /* spacing between icons */

  }

  .title-a {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .title-b {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .about-info p {
    font-size: 0.75rem;
    /* Smaller font size for text */
  }

  .button-container .btn {
    padding: 10px 20px;
    /* Smaller padding on buttons */
    font-size: 0.8rem;
    /* Smaller font size for text in buttons */
  }
}


/*
PORTFOLIO
*/

.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
}

.work-box:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 1s;
}

.work-content {
  padding: 2rem 3% 1rem 4%;
}

.work-content .w-more {
  color: #4e4e4e;
  font-size: .8rem;
}

.work-content .w-more .w-ctegory {
  color: #0078ff;
}

.work-content .w-like {
  font-size: 2.5rem;
  color: #0078ff;
  float: right;
}

.work-content .w-like a {
  color: #0078ff;
}

.work-content .w-like .num-like {
  font-size: .7rem;
}

.w-title {
  font-size: 1.2rem;
}

/*
TESTIMONIALS
*/

.testimonials .owl-carousel .owl-item img {
  width: auto;
}

.testimonial-box {
  color: #fff;
  text-align: center;
}

.testimonial-box .author-test {
  margin-top: 1rem;
}

.testimonial-box .author-test img {
  margin: 0 auto;
}

.testimonial-box .author {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 1rem 0;
  display: block;
  font-size: 1.4rem;
}

.testimonial-box .comit {
  font-size: 2rem;
  color: #0078ff;
  background-color: #fff;
  width: 52px;
  height: 52px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  line-height: 1.6;
}

.icon-svg-container svg {
  fill: currentColor;
  /* This makes the SVG color the same as the div's font color, which is set to `icon.hex` */
}

/*
CONTACT
*/

.footer-paralax {
  padding: 4rem 0 0 0;
}

.contact-mf {
  margin-top: 4rem;
}

/*
FOOTER
*/

footer {
  text-align: center;
  color: #fff;
  padding-bottom: 4rem;
}

footer .copyright {
  margin-bottom: .3rem;
}

/*
CONTACT 
*/

#sendmessage {
  color: #0078ff;
  border: 1px solid #0078ff;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
  display: block;
}

.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

/*
BUTTON
*/

.button {
  display: inline-block;
  padding: .3rem .6rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: .3rem;
  border: 1px solid transparent;
  transition: all 500ms ease;
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background-color: #0062d3;
  color: #fff;
  transition: all 500ms ease;
}

.button-a {
  background-color: #0078ff;
  color: #fff;
  border-color: #cde1f8;
}

.button-big {
  padding: .9rem 2.3rem;
  font-size: 1.2rem;
}

.button-rouded {
  border-radius: 5rem;
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

@media (min-width: 577px) {
  .counter-box {
    margin-bottom: 1.8rem;
  }
}

@media (max-width: 1024px) {
  .col-md-6 {
    width: 100%;
    /* Full width */
    padding: 15px;
    /* Reduced padding */
  }

  .about-mf .about-info {
    font-size: 1.3rem;
    /* Slightly smaller font size */
    padding: 1.5rem;
    /* Less padding to save space */
  }
}

/* Tablets and large phones (768px) */
@media (max-width: 768px) {
  .about-mf .about-info {
    font-size: 1.2rem;
    /* Even smaller font size */
    padding: 1rem;
    /* Tighter padding */
  }

  .about-img img {
    width: 80%;
    /* Reduce the size of the image */
    margin: 0 auto;
    /* Center the image */
  }
}

.image-container {
  position: relative;
  width: 100%;
  /* Use 100% of the parent container's width */
  max-width: 600px;
  /* Ensure it doesn't exceed the maximum size */
  height: 400px;
  /* Maintain aspect ratio */
  margin: 20px auto;
  /* Center the container */
}

.sneaker-animation {
  display: block;
  width: 100%;
  /* Make the image responsive to the container's width */
  height: 100%;
  /* Adjust height automatically to maintain aspect ratio */
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* margin: 20px auto; */
  /* You can remove margin here since it's on the container */
}

/* Media Query for tablets */
@media (max-width: 768px) {
  .sneaker-animation {
    height: 100%;
    /* Slightly smaller height for tablet devices */
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    object-fit: fill;
  }
}

/* Media Query for mobile devices */
@media (max-width: 480px) {
  .sneaker-animation {
    height: 100%;
    /* Reduced height for mobile devices */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    object-fit: fill;
  }
}

.fab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Align FABs at the start of the container */
  gap: 10px;
  /* Manage spacing between FABs */
}

.footer {
  background-color: #060a4e;
  color: #fff;
  /* text-align: center; */
  padding: 15px 0;
}

.footer-white {
  background-color: #fff;
  color: #060a4e;
  /* text-align: center; */
  padding: 15px 0;
}

.footer p {
  margin: 0;
}

.footer-social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  /* make bigger */
  font-size: 1.5rem;

}

.footer-social-icons li {
  padding: 0 10px;
}

.footer-social-icons a {
  color: white;
  text-decoration: none;
}

.Fab {
  margin: 5px;
  white-space: nowrap;
  /* Prevent text labels from wrapping */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.6);
  /* Dim the image a bit */
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  color: white;
  /* Text color */
  font-size: 20px;
  /* Text size */
  padding: 20px;
  /* Padding inside the overlay */
}

.image-container:hover .overlay {
  opacity: 1;
}

.text {
  text-align: center;
  padding: 15px;
}

.border {
  border: 2px solid #0062d3;
  /* black solid border, 2 pixels thick */
}

/* Small phones (480px) */
@media (max-width: 480px) {
  .about-mf .about-info {
    font-size: 1rem;
    /* Small font size for small devices */
    padding: 0.5rem;
    /* Minimal padding */
  }

  .about-img img {
    width: 100%;
    /* Full width image */
  }

  .about-info h2 {
    font-size: 2rem;
    /* Larger text for heading to maintain hierarchy */
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .cloud-grid {
    grid-template-columns: repeat(4, 1fr);
    /* Four columns for larger screens */
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cloud-grid {
    grid-template-columns: repeat(3, 1fr);
    /* Three columns for medium screens */
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cloud-grid {
    grid-template-columns: repeat(2, 1fr);
    /* Two columns for small devices */
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 767px) {
  .cloud-grid {
    grid-template-columns: 1fr;
    /* Single column for extra small devices */
  }
}

@media (min-width: 767px) {
  .about-mf .box-pl2 {
    padding-left: 2rem;
    /* Keep your original left padding */
    padding-top: 1rem;
    /* Add top padding for balance */
    padding-bottom: 1rem;
    /* Add bottom padding for balance */
    padding-right: 1rem;
    /* Add right padding to match the top and bottom */
    background-color: #060a4e;
    /* Light background for the box */
    border: 1px solid #060a4e;
    /* Subtle border to define the box edges */
    border-radius: 8px;
    /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Soft shadow for depth */
    margin-bottom: 1rem;
    /* Space below the box */
    transition: all 0.3s ease-in-out;
    /* Smooth transition for hover effects */
    width: 80%;
    display: flex;
  }

  /* Add this CSS to your stylesheet for custom tooltip styling */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Creates a two-column layout */
    gap: 20px;
    /* Space between columns and rows */
  }

  .portfolio-grid .cloud-section {
    text-align: center;
    /* Center aligns the content */
  }

  /* Add to your main CSS file */
  .cloud-grid {
    border: 2px solid #0062d3;
    /* Blue solid border, maintaining the color */
    padding: 20px;
    /* Reduced padding inside the border */
    margin: 20px auto;
    /* Reduced margin to center the grid more compactly */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Maintain two columns, adjust if necessary */
    grid-gap: 20px;
    /* Reduced space between grid items */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Lighter shadow for subtlety */
    border-radius: 10px;
    /* Slightly reduced border radius */
  }

  .cloud-item {
    /* display: grid; */
    margin-bottom: 10px;
    /* Reduced from 30px to make it tighter */
    text-align: center;
    padding: 5px;
    /* Optional: Add or reduce padding for more compactness */
    font-size: 0.9rem;
    /* Optional: Reduce font size if text is taking too much space */
  }

  .portfolio-grid .title-a {
    margin-bottom: 10px;
    /* Adds some space below the title */
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: rgb(0, 0, 0);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .box-pl2:hover {
    transform: translateY(-5px);
    /* Slight raise of the box on hover for an interactive feel */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover for emphasis */
  }

  .card-blog {
    margin-bottom: 3rem;
  }

  .contact-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }
}

@media (min-width: 768px) {
  .box-shadow-full {
    padding: 3rem;
  }

  .navbar-b.navbar-trans .nav-item,
  .navbar-b.navbar-reduce .nav-item {
    padding-left: 10px;
  }

  .navbar-b.navbar-trans .nav-link:before,
  .navbar-b.navbar-reduce .nav-link:before {
    margin-left: 18px;
  }

  .intro .intro-title {
    font-size: 4.5rem;
  }

  .intro .intro-subtitle {
    font-size: 2.5rem;
  }

  .intro-single .intro-title {
    font-size: 3.5rem;
  }

  .testimonial-box .description {
    padding: 0 5rem;
  }

  .post-box,
  .form-comments,
  .box-comments,
  .widget-sidebar {
    padding: 3rem;
  }

  .blog-wrapper .article-title {
    font-size: 1.9rem;
  }

  .box-comments .list-comments .comment-author {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .testimonial-box .description {
    padding: 0 8rem;
  }
}

@media (min-width: 1200px) {
  .testimonial-box .description {
    padding: 0 13rem;
  }
}

@media (max-width: 1024px) {
  .bg-image {
    background-attachment: scroll;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}