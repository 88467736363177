/* ProjectsGrid.css */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* This will create as many columns as can fit in the container */
    gap: 20px;
    /* This sets the gap between the grid items */
    padding: 20px;
    /* Add padding around the grid */
    justify-content: center;
    /* Center the grid items */
    margin-top: 40px;
}